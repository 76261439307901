export const InputDataTypes = {
    API_BUTTON: "apiButton",
    INTEGER: "int",
    INTEGER_ARRAY: "intArray",
    BOOL: "bool",
    STRING: "string",
    STRING_ARRAY: "stringArray",
    DECIMAL: "decimal",
    SINGLE_SELECT: "singleSelect",
    MULTI_SELECT: "multiSelect",
    DENOM: "denom",
    DATE: "date",
    JSON: "json",
    SEGMENT: "segment",
    MULTI_SEGMENTS: "multiSegments",
    PHONE_NUMBER: "phoneNumber",
    EPOCH_TIME: "epochTime",
    ROW_NUMBER: "rowNumber",
    STORAGE_FOLDER: "storageFolder",
    DYNAMIC_VALUE: "dynamicValue"
};